import { axios } from '@/utils/request'


function downloadFn(data, fileName){
  var eleLink = document.createElement('a')
  eleLink.download = fileName + '.xls';
  eleLink.style.display = 'none';
  var blob = new Blob([data],{type: 'application/vnd.ms-excel'})
  eleLink.href = URL.createObjectURL(blob)
  document.body.appendChild(eleLink)
  eleLink.click()
  document.body.removeChild(eleLink)
}


export function getList(params) {
    return axios({
      url: '/online/cgform/api/getData/65c43b3e577a4c6792024c20019e2591',
      method: 'get',
      params: params
    })
  }


  export function getColumns(tab_id) {
    return axios({
      url: '/online/cgform/api/getColumns/' + tab_id,
      method: 'get'
    })
  }

  export function getdetail(id) {
    return axios({
      url: '/online/cgform/api/form/65c43b3e577a4c6792024c20019e2591/' + id,
      method: 'get'
    })
  }


  export function download(params) {
    return axios({
      url: '/online/cgform/api/exportXls/65c43b3e577a4c6792024c20019e2591',
      method: 'get',
      params: {paramsStr: params},
      responseType: 'blob'
    }).then(res => {
      downloadFn(res, '客户周报')
    })
  }
  

  
  



  