export default {
    data() {
        return {
            selected: []
        }
    },
    methods: {
        selectAll(item) {
            if (item.value) {
                item.items.forEach((e) => {
                    this.selected.push(e.id);
                });
            } else {
                this.selected = [];
            }
        },
        selectItem(item) {
            console.log(item)
            if (item.value) {
                this.selected.push(item.item.id);
            } else {
                this.selected.splice(
                    this.selected.findIndex((e) => e === item.item.id),
                    1
                );
            }
        }
    }
}